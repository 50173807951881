<template>
	<div class="content" @click="closeJian">
		<div class="top">
			<div class="box1">
				<div class="box2">
					<img src="../assets/logo.png" @click="goHome" />
					<!-- <p>后台管理系统</p> -->
				</div>
				<div class="box3">
					<!-- {{routerList}} -->
					<div
						class="box4"
						@click="chooseTop(index, item)"
						:class="{ box4active: index === topIndex }"
						v-for="(item, index) in routerList"
						:key="index"
					>
						<span>{{ item.meta.text }}</span>
						<div v-if="index === topIndex"></div>
					</div>
				</div>
			</div>

			<div class="admininfobox">
				<el-tooltip class="item" effect="dark" content="企业中心" placement="bottom">
					<img src="@/assets/home/image14.png" class="companyicon" @click="goCompanyCenter" />
				</el-tooltip>

				<div style="display: flex;align-items: center;" @click.stop="showSelect">
					<div class="personalavatar">{{realName1}}</div>
					<!-- <img :src="userInfo.headPortrait" v-if="userInfo.headPortrait" class="personalavatar" />
					<img src="@/assets/home/image12.png" v-else class="personalavatar" /> -->
					<!--  -->
					<span>{{ userInfo.realName }}</span>
					<i :class="{ jiantou: zhuan }" class="el-icon-arrow-down"></i>
					<div class="myselect" :class="{ hideselect: !zhuan }">
						<!-- 	<div class="oneselect" @click="goEdtiPassword">
							<img src="@/assets/home/image9.png" >
							修改密码
						</div> -->
						<div class="oneselect" style="color: #F22E18;" @click="clickExit">
							<img src="@/assets/home/image8.png" />
							退出登录
						</div>
					</div>
				</div>
			</div>
		</div>
		<keep-alive><router-view></router-view></keep-alive>
	</div>
</template>

<script>
import { userPermissionTree } from '@/network/api.js';
import { mapState } from 'vuex';
export default {
	data() {
		return {
			routerList: [],
			listindex: 0,
			realName1:'',
			zhuan: false
		};
	},
	computed: {
		...mapState(['userInfo', 'permissionList', 'isadmin', 'topIndex'])
		
		
	},

	created() {
		let that = this;
		// this._userPermissionTree();
		this.$store.dispatch('_userPermissionTree', this.getRouterList);
		this.$store.dispatch('_myDetail');
		this.getRoutePath();
		// 获取系统设置
		// console.log(this.$store);
		
		this.$store.dispatch('systemModule/_allSettingList');
	
		setTimeout(function(){
			if(that.userInfo.realName.length>2){
				that.realName1 = that.userInfo.realName.substring(that.userInfo.realName.length-2)
			}else{
				that.realName1 = that.userInfo.realName
			}
		},500)
		
		
		
	},
	methods: {
		// 跳转企业中心
		goCompanyCenter() {
			this.$router.push('/company');
		},
		// 关闭箭头
		closeJian() {
			this.zhuan = false;
		},
		// 跳转首页
		goHome() {
			this.$store.commit('setTopIndex', 0);
			this.$router.push({
				path: '/home'
			});
		},
		// 跳转修改密码
		goEdtiPassword() {
			this.$store.commit('setTopIndex', -1);
			this.$router.push({
				path: '/editpassword'
			});
		},
		// 跳转公司信息
		goCompanyInfo() {
			this.$store.commit('setTopIndex', -1);
			this.$router.push({
				path: '/company'
			});
		},

		// 获取路由列表
		getRouterList() {
			let that = this;
			// console.log(this.$router.options.routes);
			if (localStorage.getItem('isStaff') === '0' && this.isadmin) {
				this.routerList = this.$router.options.routes
					.filter(item => item.path === '/')[0]
					.children.filter(item => {
						if (item.meta && item.meta.level === 0) {
							return item;
						}
					});
			} else {
				this.$router.options.routes.filter(item => item.path === '/')[0].children.filter(item => {
						if (item.meta && item.meta.level === 0) {
							 console.log(this.permissionList)
							if (item.meta.redirectlist && item.meta.redirectlist.some(item1 => this.permissionList.includes(item1.name))) {
							     that.routerList.push(item);
								
							} else if (!item.meta.redirectlist) {
								this.routerList.push(item);
							}
							
						}
					});
			}
			this.getRoutePath();
		},
		// 点击退出登录
		clickExit() {
			localStorage.setItem('adminToken', '');
			this.$router.replace({
				path: '/login'
			});
		},
		// 点击箭头转动
		showSelect() {
			this.zhuan = !this.zhuan;
		},
		// 获取当前路由地址
		getRoutePath() {
			let route = this.$route.path;
			this.routerList.forEach((item, index) => {
				if (item.path === route) {
					// this.topIndex = index;
					this.$store.commit('setTopIndex', index);
				} else if (item.meta.childrenPath && item.meta.childrenPath.includes(route)) {
					this.$store.commit('setTopIndex', index);
				} else {
					// console.log(111111);
					// this.$store.commit('setTopIndex',-1)
				}
			});
		},
		// 点击顶部导航
		chooseTop(index, item) {
			// console.log(index);
			this.$store.commit('setTopIndex', index);
			// console.log(item);

			this.$router.push({
				path: item.path
			});
		}
	}
};
</script>

<style scoped lang="scss">
.companyicon {
	width: 2em;
	height: 2em;
}
::-webkit-scrollbar {
	width: 0;
	background-color: rgb(255, 255, 255);
}
::-webkit-scrollbar-thumb {
	border-radius: 0.1875em;
	background-color: rgb(172, 172, 172);
}

.oneselect {
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	color: #5b6271;
	cursor: pointer;
	img {
		width: 0.8125em;
		width: 0.8125em;
		margin: 0 0.75em 0 0;
	}
}

.myselect {
	overflow: hidden;
	width: 100px;
	height: 40px;
	background-color: #ffffff;
	box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
	position: absolute;
	top: 3.5em;
	z-index: 999;
	right: 0;
	transition: 0.3s all;
	&.hideselect {
		height: 0;
		overflow: hidden;
		box-shadow: 0 0 0 0 #ffffff;
		transition: 0.3s all;
	}
}
.jiantou {
	transition: 0.3s all;
	transform: rotate(180deg);
}
::v-deep {
	.el-date-editor {
		width: 9.75em !important;
	}
}
.personalavatar {
	width: 2.75em;
	height: 2.75em;
	border-radius: 50%;
	margin-left: 1.625em;
	color: #fff;
	line-height: 2.75em;
	text-align: center;
	background-color: #3286ff;
	
}
.admininfobox {
	cursor: pointer;
	position: relative;
	display: flex;
	align-items: center;
	i {
		cursor: pointer;
		transition: 0.3s all;
	}
	span {
		font-size: 1em;
		font-family: PingFang-SC-Medium, PingFang-SC;
		font-weight: 500;
		color: #3a3a3a;
		margin: 0 0.625em;
	}
}
.box4:hover {
	background: rgba(50, 134, 255, 0.06);
	transition: 0.5s all;
}
.box4 {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
	transition: 0.5s all;
	height: 5em;
	font-size: 1em;
	font-family: PingFang-SC-Medium, PingFang-SC;
	font-weight: 500;
	color: #111111;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 20px;
	min-width: 5em;
	position: relative;
	&.box4active {
		background: rgba(50, 134, 255, 0.06);
	}
	div {
		width: 100%;
		height: 0.25em;
		background: #3286ff;
		border-radius: 0.625em 0.625em 0px 0px;
		position: absolute;
		bottom: 0;
	}
}
.box3 {
	display: flex;
	align-items: center;
	margin: 0 0 0 6.25em;
}
.box2 {
	height: 100%;
	overflow: hidden;
	min-width: 8.75em;
	font-size: 1.125em;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #000000;
	display: flex;
	align-items: center;
	font-weight: 600;
	img {
		cursor: pointer;
		width: 8.75em;
		height: 7.9375em;
	}
}
.box1 {
	display: flex;
	align-items: center;
	overflow: hidden;
	height: 100%;
}
.content {
	width: calc(100vw - 8px);
	// width: 100%;
	// width: 100vw;
	padding: 0px !important;
	min-height: 100vh;
	background-color: #eff3f6;
	min-width: 1000px;
	overflow-y: scroll;
}
.top {
	box-shadow: 0px 2px 0.5em 0px rgba(0, 145, 255, 0.1);
	height: 5em;
	width: 100%;
	background-color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 3.125em 0 1.25em;
	min-width: 1010px;
}
@media screen and (max-width: 1920px) {
	.content {
		font-size: 16px;
	}
}
@media screen and (max-width: 1720px) {
	.content {
		font-size: 15px;
	}
}
@media screen and (max-width: 1520px) {
	.content {
		font-size: 14px;
	}
}
@media screen and (max-width: 1320px) {
	.content {
		font-size: 12px;
	}
}
@media screen and (max-width: 1120px) {
	.content {
		font-size: 10px;
	}
}
</style>
